import { LoadingIndicator, Size } from "../LoadingIndicator/LoadingIndicator";

interface NumberFormatProps {
  value: number | string | undefined;
  percent?: boolean;
  decimals?: number;
  reverse?: boolean;
  currency?: string;
  right?: boolean;
  loading?: boolean;
  suffix?: string;
  prefix?: string;
  skeleton?: boolean;
  color?: {
    negative?: boolean;
    positive?: boolean;
  };
  loadingSize?: Size
}

export const NumberFormat = ({
  value,
  decimals,
  reverse,
  color,
  currency,
  percent,
  loading,
  prefix,
  right,
  suffix,
  skeleton,
  loadingSize = "xs"
}: NumberFormatProps) => {
  if (skeleton && loading)
    return (
      <div className="box-border w-full">
        <div className="w-2/3 text-transparent bg-gray-100 rounded-md animate-pulse select-none">
          0
        </div>
      </div>
    );
  if (loading) {
    return <div className="flex justify-center w-full"><LoadingIndicator size={loadingSize} /></div>;
  }
  suffix = suffix ? suffix : "";
  prefix = prefix ? prefix : "";
  const dec = decimals ? decimals : 0;
  if (!value) value = 0;
  let num = typeof value === "string" ? Number(value) : value;
  if (percent) num *= 100;
  num = reverse ? (num !== 0 ? num * -1 : 0) : num;
  const options = { minimumFractionDigits: dec, maximumFractionDigits: dec };
  const stringValue =
    prefix +
    num.toLocaleString("no-NO", options) +
    (currency ? " " + currency : "") +
    (percent ? "%" : "") +
    suffix;
  if (num < 0 && color?.negative) return <span className="text-red-600">{stringValue}</span>;
  if (num > 0 && color?.positive) return <span className="text-green-400">{stringValue}</span>;
  return <>{stringValue}</>;
};

//Function that number as a string as parameter and returns a number as as string formatted with spaces as thousands separator
export const formatNumber = (number: string) => {
  const num = Number(number);
  return num.toLocaleString("no-NO", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};
