import { useNavigate } from "react-router";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden h-full">
      <div className="flex flex-col justify-center py-3 w-auto h-full rounded cursor-pointer">
        <img
          onClick={() => navigate("/overview")}
          className="relative z-10 h-full cursor-pointer"
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="logo"
        />
      </div>
    </div>
  );
};
