import { HTMLProps, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

interface CardProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  heading?: ReactNode;
  options?: Options;
}

interface LoadingCardProps extends HTMLProps<HTMLDivElement> {
  heading: ReactNode;
}

export const LoadingCard = ({ heading, ...restProps }: LoadingCardProps) => {
  return (
    <Card
      { ...restProps }
      heading={ heading }
    >
      <div className="flex justify-center items-center h-32">
        <LoadingIndicator center/>
      </div>
    </Card>
  );
}


interface EmptyCardProps extends HTMLProps<HTMLDivElement> {
  heading: ReactNode;
  emptyLabel: ReactNode;
}
export const EmptyCard = ({ heading, emptyLabel, ...restProps }: EmptyCardProps) => {
  return (
    <Card
      { ...restProps }
      heading={ heading }
    >
      <div className="flex justify-center items-center h-32 text-md">
        { emptyLabel }
      </div>
    </Card>
  );
}

export const Card = ({
                       children,
                       heading,
                       options = { heading: { size: "md", hideBorder: false } },
                       ...restProps
                     }: CardProps) => {
  return (
    <div
      { ...restProps }
      className={ classNames(
        "w-full h-full rounded-xl shadow-card flex flex-col justify-start overflow-hidden",
        "shadow-custom-card",
        options.colors?.background
          ? options.colors?.background
          : "bg-white",
        restProps.className
      ) }>
      { heading && (
        <div
          className={ classNames(
            "w-full flex justify-start items-center overflow-hidden whitespace-nowrap text-ellipsis",
            options.heading?.hideBorder ? "" : "border-b",
            options.heading?.colors?.text ? options.heading?.colors?.text : "text-inherit",
            { "p-3 min-h-[3rem] max-h-[3rem]": options?.heading?.size === "md" },
            { "p-1.5 min-h-[40px]": options?.heading?.size === "sm" },
            {
              "text-xl font-bold": typeof heading === "string" && options?.heading?.size === "md",
            },
            { "text-lg font-bold": typeof heading === "string" && options?.heading?.size === "sm" }
          ) }>
          { typeof heading === "string" ? (
            <div className="overflow-hidden relative whitespace-nowrap text-ellipsis">
              { heading }
            </div>
          ) : (
            heading
          ) }
        </div>
      ) }
      { children }
    </div>
  );
};

interface Options {
  heading?: Heading;
  colors?: Colors;
}

interface Heading {
  size?: "md" | "sm" | string;
  colors?: Colors;
  hideBorder?: boolean;
}

interface Colors {
  text?: string;
  background?: string;
  gradientBg?: boolean;
}
