import { ChangeEvent, InputHTMLAttributes, forwardRef, Ref } from "react";
import classNames from "classnames";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  value?: string | number;
  type?: "text" | "number";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = forwardRef(
  (
    { value, onChange, className, type = "text", ...props }: TextInputProps,
    r: Ref<HTMLInputElement>
  ) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event);
    };

    return (
      <input
        ref={r}
        className={classNames(
          "border w-full rounded-lg py-1 border-gray-300 focus:border-prime outline-hidden ring-0 focus:ring-0 focus:outline-hidden",
          className
        )}
        type={type}
        value={value}
        onChange={handleChange}
        {...props}
      />
    );
  }
);

TextInput.displayName = "test";
