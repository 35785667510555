import classNames from "classnames";
import { MdClose } from "react-icons/md";

interface CloseButtonProps {
  onClick: () => void;
}
export const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <div
      onClick={() => onClick()}
      className={classNames(
        "rounded-full w-7 h-7 bg-gray-200 hover:bg-gray-800 hover:text-white flex justify-center items-center cursor-pointer"
      )}>
      <MdClose />
    </div>
  );
};
