import { ReactNode, useState, useEffect, useRef } from "react";
import classNames from "classnames";

interface SideBarProps {
  children: ReactNode;
  close: () => void;
  style?: {
    backgroundColor?: string;
    borderColor?: string;
  };
}
export const SideBar = ({ children, close, style }: SideBarProps) => {
  const [startSlide, setStartSlide] = useState(false);

  const backgroundColor = style?.backgroundColor || "bg-white";
  const borderColor = style?.borderColor || "border-gray-200";

  const closeSideBar = () => {
    setStartSlide(false);
    setTimeout(() => {
      close();
    }, 200);
  };
  const [yPos, setYPos] = useState(0);
  const [yPosStart, setYPosStart] = useState(0);

  useEffect(() => {
    setStartSlide(true);
    document.body.style.overflowY = "hidden";
    return () => {
      setStartSlide(false);
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <>
      <div
        style={{ transform: `translateY(${yPos - yPosStart})` }}
        onClick={() => closeSideBar()}
        className={classNames(
          "z-[400] w-full h-full top-0 left-0 right-0 bottom-0 fixed bg bg-gray-400 transition-opacity duration-200 cursor-default",
          { "opacity-50": startSlide, "opacity-0": !startSlide }
        )}></div>
      <div
        className={classNames(
          "z-[500] rounded-t-3xl md:rounded-none fixed w-full md:w-2/5 lg:w-2/5 xl:w-1/4 h-5/6 md:h-full right-0 md:top-0 bottom-0 md:border-l-4 transition-transform duration-200 cursor-default",
          backgroundColor,
          borderColor,
          {
            "translate-x-0 translate-y-0": startSlide,
            "translate-y-full md:translate-x-full md:translate-y-0": !startSlide,
          }
        )}>
        <div
          onTouchStart={(e) => {
            setYPosStart(e.targetTouches[0].clientY);
            console.log(e.targetTouches[0].clientY);
          }}
          onTouchMove={(e) => console.log(e)}
          onTouchEnd={(e) => {
            console.log(e);
          }}
          onClick={() => closeSideBar()}
          className="flex md:hidden justify-center items-center w-full h-8 border-b cursor-pointer">
          <div className="w-12 h-0.5 bg-gray-400"></div>
        </div>

        {children}
      </div>
    </>
  );
};
