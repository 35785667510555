import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import {
  SelectedContact,
  useGetContractIdData,
} from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { BiChevronDown } from "react-icons/bi";
import { BsPersonFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { Button, TextInput } from "..";
import { useGetRepresentees } from "./api/useGetRepresentees";

export const ContactSelect = () => {
  const { selectedContact, setSelectedContactId, setSelectedContact } =
    useGetContractIdData();
  const { linkedContact } = useKeycloak();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const setContact = (contact: SelectedContact) => {
    setSelectedContact(contact);
    setSelectedContactId(contact.id);
    setIsOpen(false);
    navigate("/overview");
  };

  return (
    <>
      <div className={ classNames("flex w-[12rem] justify-end", { "z-[101]": isOpen }) }>
        <div
          onClick={ () => setIsOpen((prev) => !prev) }
          className={ classNames(
            "flex max-w-[12rem] hover:max-w-[16rem] whitespace-nowrap overflow text-ellipsis select-none transition-all",
            "rounded-full group bg-gray-200 border hover:border-gray-800 h-10 flex justify-between items-center gap-3 p-1 cursor-pointer"
          ) }
        >
          <BiChevronDown className="text-gray-800 shrink-0"/>
          <div className={ "overflow-hidden whitespace-nowrap transition-all select-none text-ellipsis" }>
            { selectedContact && selectedContact.userName }
          </div>

          <div
            className={ classNames(
              "flex justify-center rounded-full h-7 w-7 items-center bg-companyColor transition-transform duration-300 shrink-0",
              { "rotate-180": isOpen }
            ) }
          >
            { isOpen ? (
              <MdClose className="text-white"/>
            ) : (
              <BsPersonFill className="text-white"/>
            ) }
          </div>
        </div>
        { isOpen && (
          <SearchContacts
            onClose={ () => setIsOpen(false) }
            onSelect={ (contact) => setContact(contact) }
            selectedContact={ selectedContact }
            linkedContact={ linkedContact }
          />
        ) }
      </div>
      { isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-transparent z-[100]"
          onClick={ () => setIsOpen(false) }
        ></div>
      ) }
    </>
  );
};

interface SearchContactsProps {
  selectedContact: SelectedContact | undefined;
  linkedContact: string | undefined;
  onSelect: (contact: SelectedContact) => void;
  onClose: () => void;
  isMobile?: boolean;
}

export const SearchContacts = ({
                                 selectedContact,
                                 linkedContact,
                                 onClose,
                                 onSelect,
                                 isMobile = false,
                               }: SearchContactsProps) => {
  const [onlyDirect, setOnlyDirect] = useState(true);
  const { data, loading } = useGetRepresentees(linkedContact, onlyDirect);
  const logOut = () => keycloakService.onAuthLogout();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  const { t } = useModifiedTranslation();
  const close = useCallback(() => {
    setIsLoaded(false);
    onClose();
  }, [setIsLoaded, onClose]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        close();
      }
    });
    return () => {
      document.body.style.overflowY = "auto";
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          close();
        }
      });
    };
  }, [close]);

  const setSelectedContact = (contact: SelectedContact) => {
    onSelect(contact);
    close();
  };

  const searchRef = useRef<HTMLInputElement>(null);
  const [searchName, setSearchName] = useState("");

  const LinkedContact = {
    id: data?.id ?? "",
    contactId: data?.contactId ?? "",
    userName: data?.name ?? "",
    initials: data?.name ?? "",
  };

  if (isMobile) {
    if (data?.representees.length === 0) return null;
    return (
      <div className="flex flex-col gap-2 p-2 max-h-[50%]">
        <div className="text-xl font-bold">Velg konto</div>
        <form
          className="flex gap-2 justify-between"
          onSubmit={ (e) => {
            e.preventDefault();
            setOnlyDirect(false);
            setSearchName(searchRef.current?.value ?? "");
          } }
        >
          <TextInput
            placeholder={ t("component.contactSelect.search") }
            ref={ searchRef }
          />
          <Button type="submit">Søk</Button>
        </form>
        <div className="overflow-y-auto border-b-4">
          { loading && (
            <div className="flex justify-center items-center h-[100px]">
              <LoadingIndicator center/>
            </div>
          ) }
          { !loading && data && (
            <ContactRow
              isSelected={ selectedContact?.id === linkedContact }
              contact={ LinkedContact }
              onClick={ (contact) => setSelectedContact(contact) }
            />
          ) }

          { selectedContact && selectedContact?.id !== linkedContact && (
            <ContactRow
              isSelected
              contact={ selectedContact }
              onClick={ (contact) => setSelectedContact(contact) }
            />
          ) }

          { data &&
            !loading &&
            data?.representees
              ?.filter(
                (x) =>
                  x.id !== linkedContact &&
                  x.id !== selectedContact?.id &&
                  x.name.toLowerCase().includes(searchName.toLowerCase())
              )
              .sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
              })
              .map((x) => {
                const contact = {
                  id: x.id,
                  contactId: x.contactId,
                  userName: x.name,
                  initials: x.name,
                };
                return (
                  <ContactRow
                    key={ x.contactId }
                    contact={ contact }
                    onClick={ (contact) => setSelectedContact(contact) }
                  />
                );
              }) }
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        id="userMenuPopup"
        className={ classNames(
          "z-[101] absolute cursor-default max-h-0 right-0 mt-10 bg-white rounded-lg shadow-card border h-auto overflow-hidden transition-[max-height]",
          "flex flex-col",
          { "max-h-[40rem]": isLoaded }
        ) }
      >
        <div>
          <form
            className="flex gap-2 justify-between p-2 min-w-[300px]"
            onSubmit={ (e) => {
              e.preventDefault();
              setOnlyDirect(false);
              setSearchName(searchRef.current?.value ?? "");
            } }
          >
            <TextInput
              placeholder={ t("component.contactSelect.search") }
              ref={ searchRef }
            />
            <Button type="submit">Søk</Button>
          </form>
        </div>
        <div className="overflow-y-auto">
          { loading && (
            <div className="flex justify-center items-center h-[100px]">
              <LoadingIndicator center/>
            </div>
          ) }
          { !loading && data && (
            <ContactRow
              isSelected={ selectedContact?.id === linkedContact }
              contact={ LinkedContact }
              onClick={ (contact) => setSelectedContact(contact) }
            />
          ) }

          { selectedContact && selectedContact?.id !== linkedContact && (
            <ContactRow
              isSelected
              contact={ selectedContact }
              onClick={ (contact) => setSelectedContact(contact) }
            />
          ) }

          { data &&
            !loading &&
            data?.representees
              ?.filter(
                (x) =>
                  x.id !== linkedContact &&
                  x.id !== selectedContact?.id &&
                  x.name.toLowerCase().includes(searchName.toLowerCase())
              )
              .sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
              })
              .map((x) => {
                const contact = {
                  id: x.id,
                  contactId: x.contactId,
                  userName: x.name,
                  initials: x.name,
                };
                return (
                  <ContactRow
                    key={ x.contactId }
                    contact={ contact }
                    onClick={ (contact) => setSelectedContact(contact) }
                  />
                );
              }) }
        </div>
        <div className="flex justify-end items-center p-2">
          <Button onClick={ logOut }>
            { t("component.contactSelect.logOut") }
          </Button>
        </div>
      </div>
    </>
  );
};

const ContactRow = ({
                      contact,
                      onClick,
                      isSelected,
                    }: {
  contact: SelectedContact;
  onClick: (contact: SelectedContact) => void;
  isSelected?: boolean;
}) => {
  return (
    <div
      onClick={ () => onClick(contact) }
      className={ classNames(
        "w-full min-w-[12rem] flex justify-between items-center p-3 gap-8 cursor-pointer hover:bg-gray-200 box-border",
        { "font-bold": isSelected }
      ) }
    >
      { contact?.userName }
      <div className="flex justify-center items-center">
        { isSelected && <div className="w-2 h-2 bg-gray-800 rounded-full"></div> }
      </div>
    </div>
  );
};
